import { PageName } from 'shared/lib/types/app/pages';
import type { RouteRecordRaw, Router } from 'vue-router';

export const moduleRoute: RouteRecordRaw = {
  path: '/assign',
  name: PageName.ASSIGN_PAGE,
  component: () => import('./pages/AssignIndex.vue'),
  children: [
    {
      name: PageName.ALL_ASSIGNS,
      path: 'all',
      component: () => import('./pages/MyAssigns.vue'),
    },
  ],
};

export default (router: Router) => {
  router.addRoute(PageName.MAIN_LAYOUT, moduleRoute);
};
