import { PageName } from 'shared/lib/types/app/pages';
import { createRouter, createWebHistory } from 'vue-router';
import { getTokenCookie } from 'shared/lib/utils/cookies';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: PageName.MAIN_LAYOUT,
      meta: {
        breadcrumbName: 'common.mainPage',
      },
      component: () => import('shared/ui/layouts/MainLayout.vue'),
      redirect: { name: PageName.ALL_ASSIGNS, query: { tab: 'allAssigns' } },
      children: [],
    },
    {
      path: '/login',
      name: PageName.LOGIN_LAYOUT,
      component: () => import('shared/ui/layouts/LoginLayout.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!getTokenCookie() && to.name !== PageName.LOGIN_PAGE)
    return next({ name: PageName.LOGIN_PAGE });

  // Проверяем, есть ли meta.saveQueryFor, формируем конфиг для фильтрации и переадресации
  if (to.name && Array.isArray(from.meta.saveQueryFor)) {
    const queryConfig = from.meta.saveQueryFor.find(
      (item: { targetPageName: string }) => item.targetPageName === to.name,
    );

    // Если есть совпадение с целевой страницей
    if (queryConfig && JSON.stringify(to.query) === '{}') {
      const filteredQuery = Object.keys(from.query)
        .filter(key => queryConfig.targetQueryKeys.includes(key))
        .reduce((acc, key) => {
          acc[key] = from.query[key]; // прокидываем значения отфильтрованным ключам
          return acc;
        }, {} as Record<string, any>);

      return next({
        name: to.name,
        params: to.params,
        query: filteredQuery, // Передаем только отфильтрованные query
      });
    }
  }

  // Если параметры уже есть или их передача не требуется
  return next();
});
