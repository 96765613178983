import 'app/assets/main.css';

import { createApp } from 'vue';

import { setupI18n } from 'i18n/index';
import { setupSentry } from 'app/plugins/sentry';
import { vMaska } from 'maska';
import { plugin as vueTransitionsPlugin } from '@morev/vue-transitions';
import App from './App.vue';
import { registerModules } from './providers/register-modules';
import { store } from './providers/store';
import { router } from './providers/router/router';
import '@morev/vue-transitions/styles';

export async function initialize() {
  const app = createApp(App);
  app.use(store);

  app.directive('maska', vMaska);
  registerModules(router);
  await setupI18n(app);
  app.use(router);
  app.use(vueTransitionsPlugin({}));
  setupSentry(app);
  app.mount('#app');
}
